<template>
  <div class="container">
    <div class="search">
      <el-form ref="form" inline :model="search" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="search.uid"
            @keyup.enter.native="Search"
            placeholder="用户uid"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="状态"
            @change="Search"
            v-model="search.status"
            clearable
          >
            <el-option :key="1" :value="1" label="未审核"></el-option>
            <el-option :key="2" :value="2" label="取消删除"></el-option>
            <el-option :key="3" :value="3" label="已删除"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="提交天数"
            @change="Search"
            v-model="search.submit_day"
            clearable
          >
            <el-option
              :key="i"
              :value="i"
              :label="i"
              v-for="i in 10"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="submitDate"
            type="daterange"
            range-separator="至"
            start-placeholder="提交开始日期"
            end-placeholder="提交结束日期"
            size="mini"
            value-format="x"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="margin-left: 16px;"
            class="btnClass"
            @click="Search"
            >搜索</el-button
          >
          <el-button
            type="text"
            class="textBtn"
            style="padding-bottom: 0px;vertical-align: bottom;"
            @click="clearCondition"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <el-table border :data="list" :empty-text="emptyText" size="mini">
      <el-table-column label="用户uid" width="120">
        <template v-slot="scope">
          {{ scope.row.uid }}
        </template>
      </el-table-column>
      <el-table-column label="用户账号" width="180">
        <template v-slot="scope">
          {{ scope.row.account }}
        </template>
      </el-table-column>
      <el-table-column label="提交原因">
        <template v-slot="scope">
          {{ scope.row.reason }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="80">
        <template v-slot="scope">
          {{
            scope.row.status === 1
              ? '未审核'
              : scope.row.status === 2
              ? '取消删除'
              : scope.row.status === 3
              ? '已删除'
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="180">
        <template v-slot="scope">
          {{
            scope.row.submit_delete_time
              ? timeToDate(scope.row.submit_delete_time)
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column label="删除时间" width="180">
        <template v-slot="scope">
          {{ scope.row.deleted_time ? timeToDate(scope.row.deleted_time) : '' }}
        </template>
      </el-table-column>
      <el-table-column label="取消删除时间" width="180">
        <template v-slot="scope">
          {{
            scope.row.cancel_delete_time
              ? timeToDate(scope.row.cancel_delete_time)
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column label="删除原因类型" width="120">
        <template v-slot="scope">
          {{ scope.row.reason_serial }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <div style="text-align: center;" v-if="scope.row.status === 1">
            <el-button
              size="mini"
              type="success"
              @click="deleteUser(scope.row.uid)"
              style="margin-bottom: 2px;"
              >删除</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="cancelDelete(scope.row.uid)"
              >取消删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
// import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      title: '',
      search: {
        page: 1,
        limit: 16,
        uid: '',
        status: '',
		submit_day: '',
		submit_start: '',
		submit_end: ''
      },
	  submitDate: '',
      list: [],
      total: 0,
      emptyText: '请按条件查询'
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search() {
		console.log('this.submitDate===>',this.submitDate)
		let submit_start = this.submitDate ? this.getTenTime(this.submitDate[0]) : ''
		let submit_end = this.submitDate ? this.getTenTime(this.submitDate[1]) : ''
      	this.api.DeleteAccountList({
			...this.search,
			submit_start,
			submit_end
		}).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          if (res.data.data.data?.length == 0 || !res.data.data.data) {
            this.emptyText = 'No Data'
          }
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
	getTenTime(date) {
		return Math.round(date.getTime()/1000).toString()
	},
    deleteUser(item) {
      this.$confirm('是否删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.UserManageDelete({
          uid: item
        }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
	// 取消删除
	cancelDelete(uid) {
        this.api.DeleteAccountCancel({ uid }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('取消删除成功')
            return
          }
          this.$message.error('取消删除失败'+ res.data.msg)
        })
	},
    getSchoolWebModuleMessageListFunc(item){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        if (Number(sessionStorage.getItem("currentPage"))) {
          this.Search()
        }
      },0)
    },
	clearCondition() {
		this.reset()
		this.Search()
	},
	reset() {
      this.search = {
        ...this.search,
        page: 1,
        limit: 16,
        uid: '',
        status: '',
		submit_day: '',
		submit_start: '',
		submit_end: ''
      }
	  this.submitDate = ''
    },
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
  },

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.container {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	.search {
		background: #F0F2F5;
		padding-top: 16px;
		margin-bottom: 24px;
	}
	.el-form {
		::v-deep(.el-form-item__label) {
			font-family: 'Manrope';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
            opacity: 0.8;
			padding-right: 0px;
		}
		.el-form-item {
			margin-left: 24px;
		}
	}
	.btnClass {
		color: #FFF;
		background-color: #FB6D07;
		border-color: #FB6D07;
	}
	.textBtn {
		color: #FB6D07;
	}
	::v-deep(thead) {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #000;
		opacity: 0.8;
		th {
			font-weight: 400;
			background-color: #F0F2F5;
		}
	}
  ::v-deep(tbody) {
    tr {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
    }
  }

}
</style>
